import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/Notify.js';
import quasarUserOptions from './quasar-user-options'
import { createPinia } from 'pinia'
const pinia = createPinia()

createApp(App).use(Quasar, { quasarUserOptions,
    plugins: {
      Notify,
    },
  }).use(router).use(pinia).mount('#app')
