<template>
  <div class="kyc-link">
    <div class="flex-style q-pt-sm" style="flex-direction: row-reverse">
      <q-btn
        flat
        no-caps
        style="color: grey"
        text-capitalize
        label="Log out"
        @click="clearStorage"
      />
    </div>
    <br />
    <div class="q-pa-md kyc-pages-link">
      <q-btn
        v-for="(item, index) in pages"
        :key="index"
        class="q-mt-md route-btn"
        flat
        :name="item.name"
        square
        color="#031F22"
        no-caps
        icon-right="navigate_next"
        :label="item.label"
        style="border-bottom: 1px solid #e9e9e9"
        :to="item.to"
      />
      <q-btn
        flat
        no-caps
        label="Share Your Own Insights"
        class="share-feedback-button text-white q-mt-md"
        @click="routeToFeedbackPage()"
      />
    </div>

    <!-- @click="shareFeedback()" -->
  </div>
</template>

<script>
import router from "@/router";
import { useRouter } from "vue-router";
export default {
  name: "KYCpages",
  setup() {
    return {
      router: useRouter(),
      pages: [
        {
          name: "Summary",
          label: "Summary",
          to: "/summary",
        },
        {
          name: "GeneralStats",
          label: "General Stats",
          to: "general-stats",
        },
        {
          name: "kycJourneyDesktop",
          label: "KYC Journey (Desktop)",
          to: "/kyc-journey",
        },
        {
          name: "kycJourneyMobile",
          label: "KYC Journey (Mobile)",
          to: "/kyc-journey-mobile",
        },
        {
          name: "backOffice",
          label: "Back Office",
          to: "/back-office",
        },
        {
          name: "fakeDocTesting",
          label: "Document Testing",
          to: "/doc-testing",
        },
        {
          name: "support",
          label: "Support",
          to: "/support",
        },
        {
          name: "dataRetention",
          label: "Data Retention",
          to: "/date-retention",
        },
        {
          name: "integration",
          label: "Integration",
          to: "/integration",
        },
        {
          name: "pricing",
          label: "Pricing",
          to: "/pricing",
        },
        // {
        //   name: "BusinessOwner",
        //   label: "BusinessOwner",
        //   to: "/business-owner",
        // },
        {
          name: "CompliancePerspective",
          label: "Compliance Perspective",
          to: "/compliance-perspective",
        },
        {
          name: "UIUX",
          label: "UI/UX",
          to: "/ui-ux",
        },
      ],
    };
  },
  methods: {
    clearStorage() {
      localStorage.clear();
      this.router.push({ path: "/" });
    },

    // Button to route on feedback review page
    routeToFeedbackPage() {
      window.open(
        "https://docs.google.com/forms/d/e/1FAIpQLSegZjyYMN9EUjDD8AU3ESiB0Y3-02LdYHOLRPOUlX7ZPVbXIg/viewform",
        "_blank"
      );
    },
  },
};
</script>

<style>
.route-btn .q-btn__content {
  justify-content: space-between !important;
}

.kyc-pages-link {
  display: grid;
}

.flex-style {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
<style scoped>
.share-feedback-button {
  background: rgb(232, 59, 63) !important;
  border-radius: 5px !important;
  font-weight: bold !important;
  width: 90% !important;
  margin: auto !important;
  margin-top: 15px !important;
  /* font-size: 13px !important; */
}
</style>
