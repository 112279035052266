<template>
  <q-header
    class="row justify-between bg-white q-pt-sm q-pb-sm"
    style="border-bottom: 1px solid #e9e9e9"
    v-if="router.currentRoute._value.name != 'Login'"
  >
    <div class="kyc-pages-link row">
      <q-tabs>
        <q-route-tab
          v-for="(item, index) in pages"
          :key="index"
          class="text-grey"
          active-class="activeClass"
          flat
          :name="item.name"
          no-caps
          :label="item.label"
          :to="item.to"
          style="margin-left: -6px"
        />
      </q-tabs>
      <q-btn
        flat
        class="text-grey"
        no-caps
        active-class="activeClass"
        label="More"
        icon-right="expand_more"
      >
        <q-menu>
          <q-list style="min-width: 100px">
            <q-item
              clickable
              v-close-popup
              v-for="item in menu"
              :key="item.name"
              @click="goToRoute(item.to)"
            >
              <q-item-section>{{ item.label }}</q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
    </div>
    <div class="flex-style" style="flex-direction: row-reverse">
      <q-btn
        flat
        no-caps
        style="color: grey"
        label="Logout"
        @click="clearStorage"
      />
      <q-btn
        flat
        no-caps
        label="Share Your Own Insights"
        class="share-feedback-button"
        @click="shareFeedback()"
      />
    </div>
  </q-header>
</template>

<script>
import router from "@/router";
import { ref, provide } from "vue";
import { useRouter } from "vue-router";

export default {
  setup() {
    return {
      tab: ref("kycJourneyDesktop"),
      router: useRouter(),
      shownavbar: ref(false),
      pages: [
        {
          name: "Summary",
          label: "Summary",
          to: "/summary",
        },
        {
          name: "GeneralStats",
          label: "General Stats",
          to: "/general-stats",
        },
        {
          name: "kycJourneyDesktop",
          label: "KYC Journey(Desktop)",
          to: "/kyc-journey",
        },
        {
          name: "kycJourneyMobile",
          label: "KYC Journey(Mob)",
          to: "/kyc-journey-mobile",
        },
        {
          name: "backOffice",
          label: "Back Office",
          to: "/back-office",
        },
        {
          name: "fakeDocTesting",
          label: "Document Testing",
          to: "/doc-testing",
        },
        {
          name: "support",
          label: "Support",
          to: "/support",
        },
        {
          name: "dataRetention",
          label: "Data Retention",
          to: "/date-retention",
        },
        {
          name: "integration",
          label: "Integration",
          to: "/integration",
        },
      ],
      menu: [
        {
          name: "pricing",
          label: "Pricing",
          to: "/pricing",
        },
        // {
        //   name: "BusinessOwner",
        //   label: "CEO Perspective",
        //   to: "/business-owner",
        // },

        {
          name: "CompliancePerspective",
          label: "Compliance Perspective",
          to: "/compliance-perspective",
        },
        {
          name: "UIUX",
          label: "UI/UX",
          to: "/ui-ux",
        },
      ],
    };
  },
  methods: {
    clearStorage() {
      localStorage.clear();
      router.push({ path: "/" });
    },
    goToRoute(route) {
      router.push({ path: route });
    },
    shareFeedback() {
      window.open(
        "https://docs.google.com/forms/d/e/1FAIpQLSegZjyYMN9EUjDD8AU3ESiB0Y3-02LdYHOLRPOUlX7ZPVbXIg/viewform",
        "_blank"
      );
    },
  },
  mounted() {
    var token = localStorage.getItem("token") ? "show" : "hide";
    console.log(router.currentRoute._value.name);
    provide("navbar", token);
  },
};
</script>

<style scoped>
.flex-style {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.activeClass {
  color: #0f6761 !important;
  text-decoration: none;
}
.share-feedback-button {
  background: rgb(232, 59, 63);
  font-weight: bold;
  /* width: 175px !important; */
  font-size: 13px !important;
}
</style>
