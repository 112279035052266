import { createRouter, createWebHistory } from "vue-router";

/**
 * Checks if user is authenticated in or not,
 * If token is valid, user will be redirected inside the backoffice
 * Otherwise, user will be redirected to the login page
 */
function guardMyRoute() {
  if (localStorage.getItem("token")) {
    return true;
  } else {
    return "/"; // go to 'login'
  }
}

const routes = [
  {
    path: "/",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue"),
  },
  {
    path: "/date-retention",
    name: "Data Retention",
    beforeEnter: (to, from, next) => {
      window.scroll({
        top: 0,
        left: 0,
      });
      next(guardMyRoute());
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/DataRetention.vue"),
  },
  {
    path: "/kyc-journey",
    name: "KYCJourney (Desktop)",
    beforeEnter: (to, from, next) => {
      window.scroll({
        top: 0,
        left: 0,
      });
      next(guardMyRoute());
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/KYCJourney.vue"),
  },
  {
    path: "/summary",
    name: "summary",
    beforeEnter: (to, from, next) => {
      window.scroll({
        top: 0,
        left: 0,
      });
      next(guardMyRoute());
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Summary.vue"),
  },
  {
    path: "/kyc-pages",
    name: "KYC Page",
    beforeEnter: (to, from, next) => {
      window.scroll({
        top: 0,
        left: 0,
      });
      next(guardMyRoute());
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/mobile/KYCPages"),
  },
  {
    path: "/kyc-journey-mobile",
    name: "KYCJourney (Mobile)",
    beforeEnter: (to, from, next) => {
      window.scroll({
        top: 0,
        left: 0,
      });
      next(guardMyRoute());
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/KYCJourneyMobile.vue"),
  },
  {
    path: "/back-office",
    name: "Back Office",
    beforeEnter: (to, from, next) => {
      window.scroll({
        top: 0,
        left: 0,
      });
      next(guardMyRoute());
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/BackOffice.vue"),
  },
  {
    path: "/doc-testing",
    name: "DocTesting",
    beforeEnter: (to, from, next) => {
      window.scroll({
        top: 0,
        left: 0,
      });
      next(guardMyRoute());
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/FakeDocTesting"),
  },
  {
    path: "/support",
    name: "Support",
    beforeEnter: (to, from, next) => {
      window.scroll({
        top: 0,
        left: 0,
      });
      next(guardMyRoute());
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Support.vue"),
  },
  {
    path: "/integration",
    name: "Integration",
    beforeEnter: (to, from, next) => {
      window.scroll({
        top: 0,
        left: 0,
      });
      next(guardMyRoute());
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Integration.vue"),
  },
  {
    path: "/pricing",
    name: "Pricing",
    beforeEnter: (to, from, next) => {
      window.scroll({
        top: 0,
        left: 0,
      });
      next(guardMyRoute());
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Pricing.vue"),
  },
  {
    path: "/ui-ux",
    name: "UI/UX",
    beforeEnter: (to, from, next) => {
      window.scroll({
        top: 0,
        left: 0,
      });
      next(guardMyRoute());
    },
    component: () => import(/* webpackChunkName: "UIUX" */ "../views/UIUX.vue"),
  },
  {
    path: "/business-owner",
    name: "BusinessOwner",
    beforeEnter: (to, from, next) => {
      window.scroll({
        top: 0,
        left: 0,
      });
      next(guardMyRoute());
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/BusinessOwner.vue"),
  },
  {
    path: "/general-stats",
    name: "GeneralStats",
    beforeEnter: (to, from, next) => {
      window.scroll({
        top: 0,
        left: 0,
      });
      next(guardMyRoute());
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/GeneralStats.vue"),
  },
  {
    path: "/compliance-perspective",
    name: "Compliance Perspective",
    beforeEnter: (to, from, next) => {
      window.scroll({
        top: 0,
        left: 0,
      });
      next(guardMyRoute());
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/CompliancePerspective.vue"
      ),
  },
  {
    path: "/404",
    name: "FourOhFour",
    component: () =>
      import(/* webpackChunkName: "404" */ "../views/FourOhFour.vue"),
  },
  {
    path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
    redirect: '/404',
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
