<template>
  <q-layout view="lHh Lpr lFf">
    <div v-if="!['Login'].includes($route.name)" >
      <Navbar v-if="$q.screen.gt.xs" />
    </div>
    <q-page-container>
      <q-page>
        <router-view />
      </q-page>
    </q-page-container>
  </q-layout>
</template>
<script>
import Navbar from "./components/layouts/Navbar";
import { useRouter } from "vue-router";
import debounce from 'lodash/debounce';
import KYCPages from "../src/components/mobile/KYCPages.vue";
export default {
  name: "LayoutDefault",
  components: {
    Navbar,
    KYCPages
},
  setup() {
    return {router:useRouter() };
  },
  mounted() {
    this.handleDebouncedScroll = debounce(this.handleScroll, 0.01);
    window.addEventListener('scroll', this.handleDebouncedScroll);
  },
  methods: {
    handleScroll() {
      // Any code to be executed when the window is scrolled
      if (window.scrollY>0) {
        //console.log('add calling handleScroll');
        //document.getElementById("fixed-nav").classList.add('nav-2');
      } else {
        //console.log('remove calling handleScroll');
        //document.getElementById("fixed-nav").classList.remove('nav-2');
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleDebouncedScroll);
  }
};
</script>
<style>
.justify{
  text-align: justify;
}
.vendors {
  font-size: 18px;
}
.border {
  border-right: 1px dotted #D9D6D0;
  border-bottom: 1px dotted #D9D6D0;
}
.border-bottom {
  border-bottom: 1px dotted #D9D6D0;
}
.border-right {
  border-right: 1px dotted #D9D6D0;
}
.icon-size {
  position: relative;
  width: 120px;
  height: 25px;
}
</style>